import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Blogs from "./pages/[blog]";
import Blog from "./pages/blog";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/blog/:slug">
            <Blogs />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}